<template>
  <v-card
    id="plans-view"
    elevation="6"
    v-if="$store.getters['auth/isStudioGuest']"
  >
    <base-material-card width="100%">
      <h1
        :style="[
          {
            'font-size': $vuetify.breakpoint.xsOnly ? '20px' : '',
          },
        ]"
      >
        {{ $t("my_plan.transform.title") }}
      </h1>
      <p class="mx-2 mx-md-0">
        {{ $t("my_plan.transform.text") }}
      </p>
      <v-row>
        <v-col cols="12" md="4" v-for="(plan, i) in plans" :key="i">
          <v-card
            class="plan_info my-1"
            style="border: 1px solid white; min-height: 100%"
            v-bind:style="[
              {
                'border-color': plan.color,
                //'background-image': `url(${require('@/assets/plans-bg.png')})`,
              },
            ]"
            v-bind:class="[
              {
                selected_plan: plan.id === $store.state.auth.user.plan_id,
                bronze_card: plan.id === 1 || plan.id === 4,
                silver_card: plan.id === 2 || plan.id === 5,
                gold: plan.id === 3 || plan.id === 6,
              },
            ]"
          >
            <div
              style="
                position: absolute;
                z-index: 0 !important;
                opacity: 0.2;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
              "
              v-bind:style="[
                {
                  'background-image': `url(${require('@/assets/plans-bg.png')})`,
                },
              ]"
            ></div>
            <v-card-title class="text-center my-5">
              <h2
                class="mx-auto"
                v-bind:style="[
                  {
                    color: plan.color,
                  },
                ]"
              >
                {{ plan.name }}
              </h2>
            </v-card-title>
            <v-card-text>
              <p
                v-if="plan.id === 1 || plan.id === 4"
                class="text-center"
                style="min-height: 50px"
              >
                {{ $t("plans.text.bronze") }}
              </p>
              <p
                v-else-if="plan.id === 2 || plan.id === 5"
                class="text-center"
                style="min-height: 58px"
              >
                {{ $t("plans.text.silver") }}
              </p>
              <p v-else class="text-center" style="min-height: 50px">
                {{ $t("plans.text.gold") }}
              </p>
              <h2 class="text-center primary--text">
                {{ $n(plan.price, "currency") }}<sub>/{{ $t("month") }}</sub>
              </h2>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                style="height: 35px !important; width: 150px"
                elevation="0"
                outlined
                v-on="on"
                @click="
                  transform.plan_id = plan.id;
                  dialog = true;
                "
                >{{ $t("my_plan.transform") }}</v-btn
              >

              <v-spacer></v-spacer>
            </v-card-actions>
            <v-list
              flat
              v-if="plan.id === 1 || plan.id === 4"
              style="width: fit-content; margin: auto"
            >
              <template
                v-for="(key, j) in $t(
                  $store.getters['auth/isStudio']
                    ? 'plans.ticks.studio.bronze'
                    : 'plans.ticks.tattooer.bronze'
                )"
              >
                <v-list-item :key="`${i}-${j}`" style="min-height: unset">
                  <v-list-item-icon class="my-0 mr-2">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title v-html="key"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-list
              flat
              v-else-if="plan.id === 2 || plan.id === 5"
              style="width: fit-content; margin: auto"
            >
              <template
                v-for="(key, j) in $t(
                  $store.getters['auth/isStudio']
                    ? 'plans.ticks.studio.silver'
                    : 'plans.ticks.tattooer.silver'
                )"
              >
                <v-list-item :key="`${i}-${j}`" style="min-height: unset">
                  <v-list-item-icon class="my-0 mr-2">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title v-html="key"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-list flat v-else style="width: fit-content; margin: auto">
              <template
                v-for="(key, j) in $t(
                  $store.getters['auth/isStudio']
                    ? 'plans.ticks.studio.gold'
                    : 'plans.ticks.tattooer.gold'
                )"
              >
                <v-list-item :key="`${i}-${j}`" style="min-height: unset">
                  <v-list-item-icon class="my-0 mr-2">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title v-html="key"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" width="300">
        <template v-slot:activator="{ on }"> </template>

        <v-card id="addCard" elevation="6" style="overflow: hidden">
          <v-card-title
            style="
              font-size: 12px;
              text-transform: uppercase;
              text-decoration: underline;
              text-align: center;
            "
            >{{ $t("billing.addCard") }}
          </v-card-title>
          <div class="close">
            <v-btn icon @click="dialog = false" small>
              <v-icon id="iconClose">mdi-close</v-icon>
            </v-btn>
          </div>
          <form action data-vv-scope="add-card-form" @submit="transformUser()">
            <v-card-text>
              <v-row>
                <v-col cols="12" class="py-0">
                  <label>{{ $t("billing.card_name") }}</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required|min:2'"
                    :error-messages="errors.collect('add-card-form.name')"
                    data-vv-name="name"
                    v-model="transform.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="py-0">
                  <label>{{ $t("billing.card_number") }}</label>
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required|min:16'"
                    :error-messages="errors.collect('add-card-form.number')"
                    data-vv-name="number"
                    v-mask="'################'"
                    v-model="transform.number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <label>{{ $t("billing.card_expiredate") }}</label
                  ><v-text-field
                    outlined
                    dense
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required|min:5'"
                    :error-messages="errors.collect('add-card-form.exp_date')"
                    v-mask="'##/##'"
                    data-vv-name="exp_date"
                    v-model="transform.exp_date"
                  ></v-text-field
                ></v-col>
                <v-col cols="6" class="py-0">
                  <label>{{ $t("billing.card_cvc") }}</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="errors.collect('add-card-form.cvc')"
                    data-vv-name="cvc"
                    v-mask="'###'"
                    v-model="transform.cvc"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                style="
                  width: 100px !important;
                  color: #363533;
                  height: 25px;
                  padding-top: 10px !important;
                "
                elevation="0"
              >
                <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                {{ $t("save", { name: $t("") }) }}
              </v-btn>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </base-material-card>
  </v-card>
  <v-card
    id="plans-view"
    elevation="6"
    v-else-if="
      $store.getters['auth/isTattooer'] &&
      !$store.getters['auth/isTattooerMain']
    "
  >
    <base-material-card width="100%">
      <h1
        :style="[
          {
            'font-size': $vuetify.breakpoint.xsOnly ? '20px' : '',
          },
        ]"
      >
        {{ $t("my_plan.transform.title") }}
      </h1>
      <p class="mx-2 mx-md-0">
        {{ $t("my_plan.transform.text") }}
      </p>
      <v-row>
        <v-col cols="12" md="4" v-for="(plan, i) in plans" :key="i">
          <v-card
            class="plan_info my-1"
            style="border: 1px solid white; min-height: 100%"
            v-bind:style="[
              {
                'border-color': plan.color,
                //'background-image': `url(${require('@/assets/plans-bg.png')})`,
              },
            ]"
            v-bind:class="[
              {
                selected_plan: plan.id === $store.state.auth.user.plan_id,
                bronze_card: plan.id === 1 || plan.id === 4,
                silver_card: plan.id === 2 || plan.id === 5,
                gold: plan.id === 3 || plan.id === 6,
              },
            ]"
          >
            <div
              style="
                position: absolute;
                z-index: 0 !important;
                opacity: 0.2;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
              "
              v-bind:style="[
                {
                  'background-image': `url(${require('@/assets/plans-bg.png')})`,
                },
              ]"
            ></div>
            <v-card-title class="text-center my-5">
              <h2
                class="mx-auto"
                v-bind:style="[
                  {
                    color: plan.color,
                  },
                ]"
              >
                {{ plan.name }}
              </h2>
            </v-card-title>
            <v-card-text>
              <p
                v-if="plan.id === 1 || plan.id === 4"
                class="text-center"
                style="min-height: 50px"
              >
                {{ $t("plans.text.bronze") }}
              </p>
              <p
                v-else-if="plan.id === 2 || plan.id === 5"
                class="text-center"
                style="min-height: 58px"
              >
                {{ $t("plans.text.silver") }}
              </p>
              <p v-else class="text-center" style="min-height: 50px">
                {{ $t("plans.text.gold") }}
              </p>
              <h2 class="text-center primary--text">
                {{ $n(plan.price, "currency") }}<sub>/{{ $t("month") }}</sub>
              </h2>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                style="height: 35px !important; width: 150px"
                elevation="0"
                outlined
                v-on="on"
                @click="
                  transform.plan_id = plan.id;
                  dialog = true;
                "
                >{{ $t("my_plan.transform") }}</v-btn
              >

              <v-spacer></v-spacer>
            </v-card-actions>
            <v-list
              flat
              v-if="plan.id === 1 || plan.id === 4"
              style="width: fit-content; margin: auto"
            >
              <template
                v-for="(key, j) in $t(
                  $store.getters['auth/isStudio']
                    ? 'plans.ticks.studio.bronze'
                    : 'plans.ticks.tattooer.bronze'
                )"
              >
                <v-list-item :key="`${i}-${j}`" style="min-height: unset">
                  <v-list-item-icon class="my-0 mr-2">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title v-html="key"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-list
              flat
              v-else-if="plan.id === 2 || plan.id === 5"
              style="width: fit-content; margin: auto"
            >
              <template
                v-for="(key, j) in $t(
                  $store.getters['auth/isStudio']
                    ? 'plans.ticks.studio.silver'
                    : 'plans.ticks.tattooer.silver'
                )"
              >
                <v-list-item :key="`${i}-${j}`" style="min-height: unset">
                  <v-list-item-icon class="my-0 mr-2">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title v-html="key"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-list flat v-else style="width: fit-content; margin: auto">
              <template
                v-for="(key, j) in $t(
                  $store.getters['auth/isStudio']
                    ? 'plans.ticks.studio.gold'
                    : 'plans.ticks.tattooer.gold'
                )"
              >
                <v-list-item :key="`${i}-${j}`" style="min-height: unset">
                  <v-list-item-icon class="my-0 mr-2">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title v-html="key"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" width="300">
        <template v-slot:activator="{ on }"> </template>

        <v-card id="addCard" elevation="6" style="overflow: hidden">
          <v-card-title
            style="
              font-size: 12px;
              text-transform: uppercase;
              text-decoration: underline;
              text-align: center;
            "
            >{{ $t("billing.addCard") }}
          </v-card-title>
          <div class="close">
            <v-btn icon @click="dialog = false" small>
              <v-icon id="iconClose">mdi-close</v-icon>
            </v-btn>
          </div>
          <form action data-vv-scope="add-card-form" @submit="transformUser()">
            <v-card-text>
              <v-row>
                <v-col cols="12" class="py-0">
                  <label>{{ $t("billing.card_name") }}</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required|min:2'"
                    :error-messages="errors.collect('add-card-form.name')"
                    data-vv-name="name"
                    v-model="transform.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="py-0">
                  <label>{{ $t("billing.card_number") }}</label>
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required|min:16'"
                    :error-messages="errors.collect('add-card-form.number')"
                    data-vv-name="number"
                    v-mask="'################'"
                    v-model="transform.number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <label>{{ $t("billing.card_expiredate") }}</label
                  ><v-text-field
                    outlined
                    dense
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required|min:5'"
                    :error-messages="errors.collect('add-card-form.exp_date')"
                    v-mask="'##/##'"
                    data-vv-name="exp_date"
                    v-model="transform.exp_date"
                  ></v-text-field
                ></v-col>
                <v-col cols="6" class="py-0">
                  <label>{{ $t("billing.card_cvc") }}</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="errors.collect('add-card-form.cvc')"
                    data-vv-name="cvc"
                    v-mask="'###'"
                    v-model="transform.cvc"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                style="
                  width: 100px !important;
                  color: #363533;
                  height: 25px;
                  padding-top: 10px !important;
                "
                elevation="0"
              >
                <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                {{ $t("save", { name: $t("") }) }}
              </v-btn>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </base-material-card>
  </v-card>
  <v-card id="plans" elevation="6" v-else>
    <base-material-card width="100%">
      <v-tabs
        v-model="tab"
        :style="
          !$vuetify.breakpoint.xsOnly
            ? ''
            : 'display: flex; justify-content: center'
        "
      >
        <v-tab href="#upgrades" active-class="color">{{
          $t("my_plan.upgrades")
        }}</v-tab>
        <v-tab
          href="#facturacion"
          :class="$vuetify.breakpoint.xsOnly ? 'px-1' : ''"
          active-class="color"
          >{{ $t("my_plan.billing") }}</v-tab
        >
        <v-tab
          active-class="color"
          href="#roles"
          :disabled="!$store.state.auth.active"
          >{{ $tc("role", 2) }}</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="upgrades">
          <v-row v-if="trial">
            <v-col class="login_error text-center">
              {{ $t("trial_period", { count: trial.end_days }) }}
            </v-col>
          </v-row>
          <v-row :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-2'">
            <template v-for="(plan, i) in plans">
              <v-col
                :key="i"
                v-if="plan.id === $store.state.auth.user.plan_id"
                cols="12"
                md="4"
                style="text-align: center; font-size: 12px"
              >
                <v-card
                  class="active"
                  style="
                    width: 90%;
                    color: var(--v-primary-base);
                    margin-right: auto;
                    margin-left: auto;
                  "
                  :style="`border: solid 1px ${plan.color}`"
                >
                  <div
                    v-if="canceled && plan.id !== 4"
                    style="
                      width: fit-content;
                      margin: -10px auto;
                      border-radius: 40px;
                    "
                    class="login_error--text px-3"
                    v-bind:style="[{ 'background-color': plan.color }]"
                  >
                    <b>
                      {{
                        $t("canceled_plan", {
                          date: $d(new Date(canceled.end), "dateShort"),
                        })
                      }}
                    </b>
                  </div>
                  <v-card-title
                    ><v-col
                      style="text-transform: uppercase"
                      :style="`color: ${plan.color}`"
                      >{{ plan.name }}</v-col
                    ></v-card-title
                  >

                  <br />
                  <div
                    style="
                      border: 1px solid var(--v-primary-base);
                      border-radius: 5em;
                      line-height: 50px;
                      margin: 0 auto;
                      width: 50%;
                      color: var(--v-primary-base);
                    "
                  >
                    <span style="font-size: 16px; font-weight: bold"
                      >{{ plan.price }}€</span
                    ><span style="font-size: 12px">/mes</span>
                  </div>
                  <br />
                  <div>
                    <a @click="$router.push({ name: 'UserPlans' })">{{
                      $t("my_plan.seemore")
                    }}</a>
                  </div>
                  <div>
                    <v-col
                      ><span style="font-weight: bold">{{
                        plan_file_ussage.formated_size
                      }}</span>
                      {{ $t("my_plan.available_of") }}
                      {{ plan_file_ussage.formated_plan_size }}</v-col
                    >
                    <v-progress-linear
                      :value="ussage"
                      class="progress-bar"
                      height="10"
                      style="width: 70%"
                    ></v-progress-linear>
                  </div>
                  <div>
                    <v-col
                      ><span style="font-weight: bold">
                        {{ users_used }} {{ $t("my_plan.users") }}</span
                      >
                      {{ $t("my_plan.available_of") }}
                      {{
                        plan.n_users != -1 ? plan.n_users : "ilimitados"
                      }}</v-col
                    >
                    <v-row
                      style="margin: 0 auto"
                      justify="center"
                      v-if="plan.n_users != -1"
                    >
                      <template v-for="(user, i) in plan.n_users">
                        <v-icon
                          :key="i"
                          large
                          color="primary"
                          :style="i < users_used ? '' : 'opacity: 0.3'"
                          >mdi-minus-thick</v-icon
                        >
                        <!--<v-progress-linear
                          v-if="true"
                          :key="i"
                          value="0"
                          class="user-progress-bar"
                          height="10"
                        ></v-progress-linear>
                        <v-progress-linear
                          v-else
                          :key="i"
                          value="0"
                          class="user-progress-bar"
                          height="10"
                        ></v-progress-linear>-->
                      </template>
                    </v-row>
                  </div>
                  <br />
                  <br />
                  <!--capacidades-->
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="canceled && plan.id !== 4"
                      style="height: 35px !important; width: 150px"
                      elevation="0"
                      outlined
                      @click="changePlan(plan.id)"
                      >{{ $t("my_plan.contract") }}</v-btn
                    >
                    <v-btn
                      v-else-if="plan.id !== 4"
                      style="height: 35px !important"
                      elevation="0"
                      @click="cancelPlan()"
                      >{{ $t("cancel") }}</v-btn
                    ><v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
                <br />
                <br />
              </v-col>
              <v-col
                :key="i"
                cols="12"
                md="4"
                style="text-align: center"
                v-else
              >
                <v-card
                  style="width: 90%; margin-right: auto; margin-left: auto"
                  :style="`border: 1px solid ${plan.color}`"
                >
                  <v-card-title
                    ><v-col
                      style="text-transform: uppercase"
                      :style="`color: ${plan.color}`"
                      >{{ plan.name }}</v-col
                    ></v-card-title
                  >
                  <br />
                  <div
                    style="
                      border: 1px solid var(--v-primary-base);
                      border-radius: 5em;
                      margin: 0 auto;
                      line-height: 50px;
                      width: 50%;
                      color: var(--v-primary-base);
                    "
                  >
                    <span style="font-size: 16px; font-weight: bold"
                      >{{ plan.price }}€</span
                    ><span style="font-size: 12px">/mes</span>
                  </div>
                  <br />
                  <div>
                    <a @click="$router.push({ name: 'UserPlans' })">{{
                      $t("my_plan.seemore")
                    }}</a>
                  </div>
                  <br />
                  <br />
                  <!--capacidades-->
                </v-card>
                <v-btn
                  style="height: 35px !important; width: 150px"
                  elevation="0"
                  outlined
                  @click="changePlan(plan.id)"
                  >{{ $t("my_plan.contract") }}</v-btn
                >
                <br />
                <br />
              </v-col>
            </template>
            <!--<v-col cols="12" md="4" style="text-align: center">
              <v-card style="width: 90%; margin-right: auto; margin-left: auto">
                <v-card-title
                  ><v-col style="text-transform: uppercase">{{
                    $t("my_plan.premium")
                  }}</v-col></v-card-title
                >
                <br />
                <div
                  style="
                    border: 1px solid var(--v-primary-base);
                    border-radius: 5em;
                    margin: 0 auto;
                    line-height: 50px;
                    width: 50%;
                    color: var(--v-primary-base);
                  "
                >
                  <span style="font-size: 16px; font-weight: bold">199€</span
                  ><span style="font-size: 12px">/mes</span>
                </div>
                <br />
                <div>
                  <a href="#">{{ $t("my_plan.seemore") }}</a>
                </div>
                <br />
                <br />
                <!--capacidades--
              </v-card>
              <v-btn style="height: 35px !important" elevation="0">{{
                $t("my_plan.contract")
              }}</v-btn>
              <br />
              <br />
            </v-col>-->
          </v-row>
        </v-tab-item>
        <v-tab-item
          value="facturacion"
          :class="$vuetify.breakpoint.xsOnly ? 'px-3' : 'pl-6'"
        >
          <v-row>
            <v-col cols="12" md="5">
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-card elevation="6">
                    <v-card-title
                      ><v-col class="pb-0" style="text-transform: uppercase"
                        ><u style="font-size: 16px !important">{{
                          $t("billing.billing_address")
                        }}</u></v-col
                      ></v-card-title
                    >
                    <v-card-text
                      class="pl-7 py-0"
                      style="text-transform: uppercase; font-weight: bold"
                      >{{ facturationInfo.name || "-" }}</v-card-text
                    >
                    <v-card-text class="pl-7 py-0">{{
                      facturationInfo.address.line1 || "-"
                    }}</v-card-text>
                    <v-card-text class="pl-7 py-0"
                      >{{ second_line }}
                    </v-card-text>
                    <v-card-text class="pl-7 py-0"
                      >{{ $t("billing.tax_id_number") }}:
                      {{
                        facturationInfo.tax_id
                          ? facturationInfo.tax_id.data[0].value
                          : "-"
                      }}</v-card-text
                    >
                    <v-row justify="end"
                      ><!--botones puestos aquí hasta conseguir funcionalidad del backend, realmente van dentro de la card que tiene edit="false"-->

                      <v-col cols="3" md="3" class="pr-0"
                        ><v-btn
                          style="width: 100%; height: 5px !important"
                          outlined
                          v-model="edit"
                          @click="edit = !edit"
                          >{{ $t("billing.edit") }}</v-btn
                        ></v-col
                      >
                      <v-col cols="5" class="pr-8"><Invoices /></v-col>
                    </v-row>
                  </v-card>
                  <v-dialog v-model="edit" width="401" persistent>
                    <v-card elevation="6">
                      <v-card-title style="text-transform: uppercase"
                        ><u style="font-size: 24px !important">{{
                          $t("billing.billing_address")
                        }}</u></v-card-title
                      >
                      <div class="close">
                        <v-btn
                          icon
                          @click="
                            edit = false;
                            fetchFacturationInfo();
                          "
                          small
                        >
                          <v-icon id="iconClose">mdi-close</v-icon>
                        </v-btn>
                      </div>
                      <form
                        action
                        id="form-edit-facturation"
                        data-vv-scope="facturation-form"
                        @submit="updateFacturationInfo()"
                      >
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" class="pt-0 pb-0">
                              <label>{{ $t("billing.company_name") }}</label>
                              <v-text-field
                                outlined
                                dense
                                data-vv-validate-on="blur"
                                v-validate="'required'"
                                :error-messages="
                                  errors.collect('facturation-form.name')
                                "
                                data-vv-name="name"
                                hide-details
                                v-model="facturationInfo.name"
                              ></v-text-field
                            ></v-col>

                            <v-col cols="12" class="pt-0 pb-0">
                              <label>{{ $t("billing.address") }}</label>
                              <v-text-field
                                outlined
                                dense
                                data-vv-validate-on="blur"
                                v-validate="'required'"
                                :error-messages="
                                  errors.collect('facturation-form.line1')
                                "
                                data-vv-name="line1"
                                hide-details
                                v-model="facturationInfo.address.line1"
                              ></v-text-field
                            ></v-col>

                            <v-col cols="6" class="pt-0 pb-0">
                              <label>{{ $t("billing.postal_code") }}</label>
                              <v-text-field
                                outlined
                                dense
                                data-vv-validate-on="blur"
                                v-validate="'required'"
                                :error-messages="
                                  errors.collect('facturation-form.postal_code')
                                "
                                data-vv-name="postal_code"
                                hide-details
                                v-model="facturationInfo.address.postal_code"
                              ></v-text-field
                            ></v-col>
                            <v-col class="pt-0 pb-0" cols="6">
                              <label>{{ $t("billing.city") }}</label>
                              <v-text-field
                                outlined
                                dense
                                data-vv-validate-on="blur"
                                v-validate="'required'"
                                :error-messages="
                                  errors.collect('facturation-form.city')
                                "
                                data-vv-name="city"
                                hide-details
                                v-model="facturationInfo.address.city"
                              ></v-text-field
                            ></v-col>

                            <v-col class="pt-0 pb-0" cols="6">
                              <label>{{ $t("billing.country") }}</label>
                              <v-text-field
                                outlined
                                dense
                                data-vv-validate-on="blur"
                                v-validate="'required'"
                                :error-messages="
                                  errors.collect('facturation-form.state')
                                "
                                data-vv-name="state"
                                hide-details
                                v-model="facturationInfo.address.state"
                              ></v-text-field
                            ></v-col>
                            <v-col class="pt-0 pb-5" cols="6">
                              <label>{{ $t("billing.tax_id_number") }}</label>
                              <v-text-field
                                outlined
                                dense
                                data-vv-validate-on="blur"
                                v-validate="'required|cif'"
                                :error-messages="
                                  errors.collect('facturation-form.tax_id')
                                "
                                data-vv-name="tax_id"
                                hide-details
                                v-model="facturationInfo.dni"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            @click="
                              edit = false;
                              fetchFacturationInfo();
                            "
                            style="height: 30px; width: 150px"
                            elevation="0"
                            outlined
                          >
                            {{ $t("cancel", { name: $t("") }) }}</v-btn
                          >
                          <v-btn
                            type="submit"
                            style="height: 30px; color: #363533"
                            elevation="0"
                          >
                            <v-icon style="margin-right: 5px" size="14px"
                              >$save</v-icon
                            >{{ $t("save", { name: $t("") }) }}</v-btn
                          >
                        </v-card-actions>
                      </form>
                    </v-card>
                  </v-dialog>
                </v-col>

                <!--<v-row justify="end"
                >botones puestos aquí hasta conseguir funcionalidad del backend, realmente van dentro de la card que tiene edit="false"

                <v-col cols="2" md="4" lg="3"
                  ><v-btn v-model="edit" @click="edit = !edit">{{
                    $t("billing.edit")
                  }}</v-btn></v-col
                >
                <v-col cols="3"
                  ><v-btn>{{ $t("billing.view_invoices") }}</v-btn></v-col
                >
                </v-row>-->
                <v-col cols="12" class="py-0">
                  <v-card elevation="6">
                    <v-card-title
                      ><v-col style="text-transform: uppercase" class="pb-0"
                        ><u style="font-size: 16px !important">{{
                          $t("billing.way_to_pay")
                        }}</u></v-col
                      ></v-card-title
                    >
                    <v-card-text v-if="paymentMethods.length > 0" class="pb-0">
                      <v-row
                        v-for="paymentMethod in paymentMethods"
                        :key="paymentMethod.id"
                      >
                        <v-col cols="1" class="py-0">
                          <v-btn
                            style="margin-top: -5px"
                            icon
                            small
                            v-if="
                              facturationInfo.invoice_settings &&
                              facturationInfo.invoice_settings
                                .default_payment_method === paymentMethod.id
                            "
                          >
                            <v-icon>mdi-star</v-icon></v-btn
                          >
                          <v-hover v-slot:default="{ hover }" v-else>
                            <v-btn
                              style="margin-top: -5px"
                              icon
                              small
                              @click="setDefaultPaymentMethod(paymentMethod)"
                            >
                              <v-icon>{{
                                hover ? "mdi-star" : "mdi-star-outline"
                              }}</v-icon>
                            </v-btn>
                          </v-hover>
                        </v-col>
                        <v-col class="py-0">
                          **** **** ****
                          {{ paymentMethod.card.last4 }}</v-col
                        >
                        <v-col class="py-0">
                          {{ paymentMethod.card.brand }}
                        </v-col>

                        <v-col class="pb-0" cols="12">
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="
                          dialog = true;
                          card = {};
                        "
                        outlined
                        style="height: 5px !important"
                        ><v-icon class="pr-3" small>mdi-plus</v-icon
                        >{{ $t("billing.addCard") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="7" class="pa-0">
              <paymentSistem />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="roles">
          <users class="my-5" />
        </v-tab-item>
      </v-tabs-items>

      <v-dialog v-model="dialog" width="300">
        <v-card id="addCard" elevation="6" style="overflow: hidden">
          <v-card-title
            style="
              font-size: 12px;
              text-transform: uppercase;
              text-decoration: underline;
              text-align: center;
            "
            >{{ $t("billing.addCard") }}
          </v-card-title>
          <div class="close">
            <v-btn icon @click="dialog = false" small>
              <v-icon id="iconClose">mdi-close</v-icon>
            </v-btn>
          </div>
          <form
            action
            data-vv-scope="add-card-form"
            @submit="addUserPaymentMethod()"
          >
            <v-card-text>
              <v-row>
                <v-col cols="12" class="py-0">
                  <label>{{ $t("billing.card_name") }}</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required|min:2'"
                    :error-messages="errors.collect('add-card-form.name')"
                    data-vv-name="name"
                    v-model="card.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="py-0">
                  <label>{{ $t("billing.card_number") }}</label>
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required|min:16'"
                    :error-messages="errors.collect('add-card-form.number')"
                    data-vv-name="number"
                    v-mask="'################'"
                    v-model="card.number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <label>{{ $t("billing.card_expiredate") }}</label
                  ><v-text-field
                    outlined
                    dense
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required|min:5'"
                    :error-messages="errors.collect('add-card-form.exp_date')"
                    v-mask="'##/##'"
                    data-vv-name="exp_date"
                    v-model="card.exp_date"
                  ></v-text-field
                ></v-col>
                <v-col cols="6" class="py-0">
                  <label>{{ $t("billing.card_cvc") }}</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="errors.collect('add-card-form.cvc')"
                    data-vv-name="cvc"
                    v-mask="'###'"
                    v-model="card.cvc"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                style="
                  width: 100px !important;
                  color: #363533;
                  height: 25px;
                  padding-top: 10px !important;
                "
                elevation="0"
              >
                <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                {{ $t("save", { name: $t("") }) }}
              </v-btn>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </base-material-card>
  </v-card>
</template>
<script>
import { mask } from "vue-the-mask";
import { mapActions, mapState } from "vuex";
export default {
  name: "Plan",
  directives: {
    mask,
  },
  components: {
    users: () => import("@/views/users/UserList"),
    Invoices: () => import("./Invoices"),
    paymentSistem: () => import("@/components/user/settings/PaymentSistem"),
  },
  computed: {
    ussage() {
      return this.plan_file_ussage.size / this.plan_file_ussage.plan_size;
    },
    second_line() {
      let text = "";
      if (this.facturationInfo.address.postal_code)
        text += this.facturationInfo.address.postal_code;
      if (this.facturationInfo.address.city) {
        if (text !== "") text += " ";

        text += this.facturationInfo.address.city;
      }
      if (this.facturationInfo.address.state) {
        if (text !== "") text += ", ";

        text += this.facturationInfo.address.state;
      }
      if (text === "") text = "-";
      return text;
    },
    ...mapState("app", ["tab_state"]),
    tab: {
      get() {
        return this.tab_state.plan;
      },
      set(value) {
        return this.$store.commit("app/SET_TAB", { tab: "plan", value });
      },
    },
  },
  data() {
    return {
      //tab: "upgrades",
      edit: false,
      dialog: false,
      card: {},
      plans: [],
      facturationInfo: { address: {} },
      paymentMethods: [],
      plan_file_ussage: {},
      users_used: 0,
      trial: null,
      canceled: null,
      transform: {},
    };
  },
  mounted() {
    this.fetchUserPlans();
    if (!this.$store.getters["auth/isStudioGuest"]) {
      this.fetchFacturationInfo();
      this.fetchUserPaymentMethods();
      this.fetchPlanFileUssage();
      this.fetchUsers();
      this.fetchPlanState();
    }
  },
  methods: {
    ...mapActions("studios", ["tranformStudio"]),
    transformUser() {
      let dates = this.transform.exp_date.split("/");
      this.transform.exp_month = dates[0];
      this.transform.exp_year = dates[1];
      this.tranformStudio(this.transform).then(() => {
        console.log("BACK!");
        this.dialog = false;
        this.$alert($t("my_plan.transformed"));
        this.$router.push({ name: "Dashbord" });
      });
    },
    ...mapActions("files", ["getSizeUsed"]),
    fetchPlanFileUssage() {
      this.getSizeUsed().then((data) => {
        this.plan_file_ussage = data;
      });
    },
    ...mapActions("plans", [
      "getUserPlans",
      "setUserPlan",
      "cancelUserPlan",
      "getUserFacturationInfo",
      "setUserFacturationInfo",
      "getUserPaymentMethods",
      "setUserPaymentMethods",
      "setUserDefaultPaymentMethod",
      "checkUserPaymentMethods",
      "getPlanState",
    ]),
    fetchPlanState() {
      this.getPlanState().then((data) => {
        //console.log(data);
        let today = new Date();
        today = today.getTime();
        if (today < data.subscription.trial_end * 1000) {
          this.trial = {
            end: data.subscription.trial_end * 1000,
            end_days: Math.ceil(
              (data.subscription.trial_end * 1000 - today) / (1000 * 3600 * 24)
            ),
          };
          if (this.paymentMethods.length === 0) {
            this.$alert(this.$t("plans.message"));
          }
        } else {
          this.trial = null;
        }
        if (data.subscription.status === "canceled") {
          //console.log(new Date(data.subscription.current_period_end));
          this.canceled = {
            end: data.subscription.current_period_end * 1000,
          };
        } else {
          this.canceled = null;
        }
      });
    },
    fetchUserPlans() {
      //console.log("FETCH PLANs");
      this.getUserPlans().then((data) => {
        //console.log(data);
        this.plans = data;
        //console.log(this.plans);
      });
    },
    ...mapActions("users", ["countUsers"]),
    fetchUsers() {
      this.countUsers().then((data) => {
        this.users_used = data;
      });
    },
    fetchFacturationInfo() {
      this.getUserFacturationInfo().then((data) => {
        this.facturationInfo = data;
        //console.log("plan", this.facturationInfo.tax_id);
        if (data.tax_id.data.length === 0) {
          //console.log("no tax");
          this.facturationInfo.tax_id = { data: [{ value: null }] };
        } else {
          this.facturationInfo.dni = data.tax_id.data[0].value;
        }
      });
    },

    fetchUserPaymentMethods() {
      this.getUserPaymentMethods().then((data) => (this.paymentMethods = data));
    },
    changePlan(id) {
      this.$confirm(this.$t("billing.changePlan"), "", "").then(() => {
        this.checkUserPaymentMethods().then((data) => {
          if (data.check) {
            this.setUserPlan(id).then(() => {
              this.fetchUserPlans();
              this.fetchPlanState();
            });
          } else {
            this.$alert(this.$t("billing.no_pm"), "", "");
            this.dialog = true;
          }
        });
      });
    },
    cancelPlan() {
      this.$confirm("¿Seguro que quieres canelar el plan?").then((x) => {
        this.cancelUserPlan().then((x) => {
          this.fetchPlanState();
        });
      });
    },
    updateFacturationInfo() {
      this.errors.clear();
      //console.log("validando");

      this.$validator.validateAll("facturation-form").then((result) => {
        //console.log("validando");

        if (result) {
          this.setUserFacturationInfo(this.facturationInfo).then((data) => {
            this.facturationInfo = data;
            this.edit = false;
            this.$alert("Guardado correctamente", "", "");
          });
        }
      });
    },
    addUserPaymentMethod() {
      this.errors.clear();
      //console.log("validando");

      this.$validator.validateAll("add-card-form").then((result) => {
        //console.log("validando");

        let dates = this.card.exp_date.split("/");
        this.card.exp_month = dates[0];
        this.card.exp_year = dates[1];
        if (
          new Date("01/" + this.card.exp_date).getTime() < new Date().getTime()
        ) {
          //console.log("error");
        }
        if (result)
          this.setUserPaymentMethods(this.card).then((data) => {
            if (data.error) {
              this.$alert("Datos de la tarjeta incorrectos");
            } else {
              this.paymentMethods = data;
              this.fetchFacturationInfo();
              this.dialog = false;
              this.$alert(this.$t("save_ok"));
            }
          });
      });
    },
    setDefaultPaymentMethod(pm) {
      this.$confirm(
        this.$t("billing.confirm_pm", { target: pm.card.last4 }),
        "",
        ""
      ).then(() => {
        this.setUserDefaultPaymentMethod(pm.id).then(() => {
          this.fetchFacturationInfo();
        });
      });
    },
  },
};
</script>
<style scoped lang="sass">
.price input
  text-align: center

.price
  width: 30%
  margin: 0 auto

.v-card__text
  color: white !important
  font-size: 12px

.v-btn--contained
  height: 29px !important
  width: 80px !important
u
  font-size: 12px
.progress-bar
  width: 30%
  height: 15px
  margin: 0 auto

.v-btn.v-btn--contained.theme--dark.v-size--default
  width: 100px !important

.active
  border: 1px solid var(--v-primary-base)
  background-color: var(--v-gris2-base)

input
  font-size: 12px
#form-edit-facturation,
#addCard
  label
    font-size: 12px
    display: block
    line-height: 5 !important
    height: 35px

.user-progress-bar
  width: 15%
  height: 15px
  margin: 0 auto
</style>
<style lang="sass">
#plans

  label

  .v-btn.v-btn--contained.theme--dark.v-size--default
    width: 100px !important
#plans-view
  .v-responsive__sizer
    display: none
  .v-card.plan_info
    .v-list
      background-color: transparent
    &.selected_plan
      border: 1px solid var(--v-primary-base)
  .plan_info
    *
      z-index: 9 !important
  .bronze_card
    b
      color: var(--v-bronze-base)
  .silver_card
    b
      color: var(--v-silver-base)
  .gold
    b
      color: var(--v-primary-base)
  .v-list-item__title
    font-weight: normal !important
    b
      font-weight: bold
</style>
